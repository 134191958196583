import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import InfinityList from '@/components/common/InfinityList';
import Enrollment from '@/models/Enrollment';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ModalUpdates from '../common/modals/ModalUpdates';
import { LoadingIcon } from '../icons';
import { StudentReport as Report } from './StudentReport';
import { useActiveStudents } from './StudentReports.hook';

export type StudentReportPermissions = {
  hasPresencePermission: boolean;
  hasReportsPermission: boolean;
};

interface StudentReportsProps {
  klass: Klass;
  handleEnrollment(enrollment: Enrollment): void;
  homeworkId?: number;
  permissions: StudentReportPermissions;
  scheduledLesson: ScheduledLesson;
  editingAllowed?: boolean;
}

export function StudentReports(props: StudentReportsProps) {
  const { klass, scheduledLesson } = props;
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const [status, setStatus] = useState(true);

  const {
    enrollments,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading: loadingEnrollments,
    error: enrollmentsError,
  } = useActiveStudents({
    active: status,
    klassId: klass.id,
    scheduledLessonId: scheduledLesson.id,
  });

  return (
    <div
      data-testid="studentReports"
      className="flex flex-col p-5 shadow-default rounded-xl bg-base-100 gap-4 max-h-full"
    >
      <StatusToggle handleChecked={setStatus} />

      <div data-testid="studentsPreview" className="flex flex-col gap-4">
        <InfinityList
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={fetchNextPage}
          className="min-h-40 max-h-96 flex flex-col gap-4 "
          scroll
        >
          <ConditionalRenderer condition={loadingEnrollments}>
            <div className="flex items-center justify-center grow">
              <LoadingIcon className="w-20 text-primary-content" />
            </div>
          </ConditionalRenderer>

          <ConditionalRenderer condition={enrollmentsError}>
            <Text
              text={getErrorMessage(enrollmentsError)}
              format="rubik-400"
              className="text-primary"
              size="text-14"
            />
          </ConditionalRenderer>

          <ConditionalRenderer
            condition={!enrollments.length && !loadingEnrollments}
            fallback={
              <Fragment>
                {enrollments.map((enrollment, index) => (
                  <Report
                    key={enrollment.id}
                    {...props}
                    enrollment={enrollment}
                    setButtonRef={index === 0 ? setRef : undefined}
                  />
                ))}
              </Fragment>
            }
          >
            <Text text={t('noStudents')} />
          </ConditionalRenderer>
        </InfinityList>
      </div>
      <ConditionalRenderer condition={!!ref}>
        <ModalUpdates
          placement="right-start"
          keyword="notifyLatelessButton"
          target={ref}
        />
      </ConditionalRenderer>
    </div>
  );
}

type StatusToggleProps = {
  defaultChecked?: boolean;
  handleChecked(checked: boolean): void;
};

function StatusToggle({
  defaultChecked = true,
  handleChecked,
}: StatusToggleProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const [checked, setChecked] = useState(defaultChecked);

  function onChangeChecked(checked: boolean) {
    setChecked(checked);
    handleChecked(checked);
  }

  return (
    <div className="flex gap-2 items-center">
      <Text
        testId="studentsTitle"
        text={t('students')}
        format="rubik-500"
        className="text-center text-primary w-fit flex"
      />
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={({ target }) => onChangeChecked(target.checked)}
        className="toggle toggle-sm toggle-primary mx-2"
      />
      <Text text={checked ? t('active') : t('inactive')} className="flex" />
    </div>
  );
}
