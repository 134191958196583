import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import ScheduledLessonReport from '@/models/ScheduledLessonReport';
import {
  AnnotationIcon,
  BookOpenIcon,
  EmojiHappyIcon,
  LightningBoltIcon,
  StarIcon,
  ThumbUpIcon,
} from '@heroicons/react/outline';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Text from '../common/dataDisplay/Text';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import Popup from '../common/Popup';
import Skeleton from '../common/Skeleton';
import { CoinIcon } from '../icons';

type StudentReportAssessmentProps = {
  report?: ScheduledLessonReport;
  testId: string;
  homeworkProgress?: HomeworkActivityProgress;
  loading?: boolean;
};

export function StudentReportAssessment({
  report,
  testId,
  homeworkProgress,
  loading,
}: StudentReportAssessmentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const presenceReward = report?.presenceReward
    ? Math.round(report.presenceReward)
    : null;

  const reports = useMemo(
    () => [
      {
        icon: (
          <LightningBoltIcon
            className={`h-6 ${
              report?.challenge ? 'text-accent' : 'text-neutral/50'
            }`}
          />
        ),
        text: report?.challenge,
        testId: 'challengeGrade',
        translate: t('challenge'),
      },
      {
        icon: (
          <StarIcon
            className={`h-6 ${
              report?.effort ? 'text-yellow-500' : 'text-neutral/50'
            }`}
          />
        ),
        text: report?.effort,
        testId: 'effortGrade',
        translate: t('effort'),
      },
      {
        icon: (
          <EmojiHappyIcon
            className={`h-6 ${
              report?.behavior ? 'text-primary' : 'text-neutral/50'
            }`}
          />
        ),
        text: report?.behavior,
        testId: 'behaviorGrade',
        translate: t('behavior'),
      },
    ],
    [report?.behavior, report?.challenge, report?.effort, t],
  );

  const calculateAverage = (report: ScheduledLessonReport) => {
    let average = 0;
    const grades = [report.behavior, report.challenge, report.effort];
    const filterIfHasGrade = grades.filter(grade => !!grade);

    if (filterIfHasGrade.length) {
      let sum = filterIfHasGrade.reduce((sum, i) => {
        if (sum && i) return sum + i;
        else return 0;
      });

      if (sum) average = sum;
    }

    return Math.round(average / filterIfHasGrade.length) || null;
  };

  const average = report ? calculateAverage(report) : 0;

  return (
    <div
      data-testid={testId}
      className="flex gap-x-2.5 justify-between items-center"
    >
      <span className="flex justify-start items-center w-12 gap-x-1">
        <BookOpenIcon
          className={`h-6 ${
            homeworkProgress?.activityProgress.grade
              ? 'text-secondary'
              : 'text-neutral/50'
          }`}
        />
        <Text
          testId="homeworkGrade"
          text={homeworkProgress?.activityProgress.grade}
          format="rubik-500"
          className="text-center h-fit"
          size="text-14"
        />
      </span>
      <Popup
        className={{ popup: 'z-50' }}
        placement="bottom"
        reference={
          <label className="flex justify-start items-center w-12 gap-x-1">
            <ConditionalRenderer
              condition={!loading}
              fallback={
                <Skeleton className="h-6 bg-primary/40 animate-pulse rounded-full w-6" />
              }
            >
              <ThumbUpIcon
                className={`h-6 ${
                  average ? 'text-success' : 'text-neutral/50'
                }`}
              />
            </ConditionalRenderer>
            <ConditionalRenderer condition={report}>
              <Text
                testId="mediaGrade"
                text={average}
                format="rubik-500"
                className="text-center h-fit"
                size="text-14"
              />
            </ConditionalRenderer>
          </label>
        }
        hover
      >
        <div className="menu p-2 shadow rounded-box w-44 bg-base-100 gap-2 z-10">
          {reports.map(report => (
            <div
              key={report.testId}
              className="flex justify-start items-center w-12 gap-x-1"
            >
              <div className="flex gap-1 items-center">
                {report.icon}
                <Text
                  testId={report.testId}
                  text={report.text}
                  format="rubik-500"
                  className="text-center h-fit"
                  size="text-14"
                />
              </div>
              <Text
                text={report.translate}
                format="rubik-500"
                className="text-center h-fit"
                size="text-14"
              />
            </div>
          ))}
        </div>
      </Popup>

      <span className="flex justify-start items-center w-12 gap-x-1">
        <ConditionalRenderer
          condition={!loading}
          fallback={
            <Skeleton className="h-6 bg-primary/40 animate-pulse rounded-full w-6" />
          }
        >
          <CoinIcon
            className={`h-6 w-6 ${
              presenceReward ? 'text-yellow-400' : 'text-neutral/50'
            }`}
          />
          <Text
            testId="homeworkGrade"
            text={presenceReward}
            format="rubik-500"
            className="text-center h-fit"
            size="text-14"
          />
        </ConditionalRenderer>
      </span>
      <ConditionalRenderer
        condition={!loading}
        fallback={
          <Skeleton className="h-6 bg-primary/40 animate-pulse rounded-md w-8" />
        }
      >
        <Tooltip
          color="accent"
          text={report?.notes || t('studentHasNoComments')}
          className="cursor-none"
        >
          <AnnotationIcon
            data-testid="annotationIcon"
            className={`h-6 ${
              report?.notes ? 'text-primary' : 'text-neutral/50'
            }`}
          />
        </Tooltip>
      </ConditionalRenderer>
    </div>
  );
}
