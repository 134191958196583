import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import moment from 'moment';

type Roles = {
  klass?: Klass;
  scheduledLesson?: ScheduledLesson;
};

export const ROLES = {
  SCHEDULED_LESSON: {
    isDelayed: ({ klass, scheduledLesson }: Roles) => {
      if (!klass || !scheduledLesson) return false;

      const { enrollsCount, duration } = klass;
      const { datetime, hasDone } = scheduledLesson;

      const now = moment();

      const isBeforeNow = moment(datetime)
        .add(duration, 'minutes')
        .isBefore(now);

      const delayed = !hasDone && isBeforeNow && enrollsCount > 0;

      return delayed;
    },
    isTodayLessonTime: ({ klass, scheduledLesson }: Roles) => {
      if (!klass || !scheduledLesson) return false;

      const { duration } = klass;
      const { datetime, url } = scheduledLesson;

      const now = moment();

      const isClassroomTime =
        moment(now).isBetween(
          moment(datetime).subtract(30, 'minutes'),
          moment(datetime).add(duration, 'minutes'),
        ) && !!url;

      return isClassroomTime;
    },

    isTodayLessonDate: ({ scheduledLesson }: Roles) => {
      if (!scheduledLesson) return false;
      const { datetime } = scheduledLesson;

      const now = moment();

      const isClassroomDay = moment(datetime).isSame(now, 'day');

      return isClassroomDay;
    },
  },
};
