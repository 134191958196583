import { CheckCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import PresenceCheckbox from '@/components/common/dataDisplay/PresenceCheckbox';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ModalDisable from '@/components/common/modals/ModalDisable';
import Skeleton from '@/components/common/Skeleton';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useListService from '@/data/hook/useListService';
import useStudentReport from '@/data/hook/useStudentReport';
import {
  homeworkProgressQueryKeys,
  klassesQueryKeys,
} from '@/data/services/querykeys';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  ActiveEnum,
  EnrollmentAndReportStatus,
  PresenceEnum,
  ScheduledLessonReportEnum,
  TranferredEnum,
} from '@/models/ScheduledLessonReport';
import { studentReportStatus } from '@/utils/studentReportStatus';
import { StudentReportAssessment as ReportAssessment } from '../teacher/StudentReportAssessment';
import NotifyLatelessButton from './NotifyLatelessButton';
import { StudentReportPermissions } from './StudentReports';
import { useQuery } from '@tanstack/react-query';

type onConfirm = {
  presence: PresenceEnum;
  reportId: number;
};

type StudentReportProps = {
  enrollment: Enrollment;
  handleEnrollment: (enrollment: Enrollment) => void;
  permissions: StudentReportPermissions;
  scheduledLesson: ScheduledLesson;
  homeworkId?: number;
  setButtonRef?: (e: HTMLElement) => void;
};

export function StudentReport({
  enrollment,
  handleEnrollment,
  permissions,
  scheduledLesson,
  homeworkId,
  setButtonRef,
}: StudentReportProps) {
  const studentId = enrollment.student.id;

  const scheduledLessonId = scheduledLesson.id;

  const {
    report,
    loadingReport,
    create,
    update,
    loadingCreate,
    loadingUpdate,
  } = useStudentReport({
    studentId,
    scheduledLessonId,
  });

  const { data: klass } = useQuery(klassesQueryKeys.get(enrollment.klassId));

  const { results: homeworkProgresses } = useListService({
    ...homeworkProgressQueryKeys.list(homeworkId ?? 0, {
      studentId: enrollment.student.id,
    }),
    enabled: !!homeworkId && !!enrollment.student.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const homeworkProgress = homeworkProgresses.at(0);

  const [visible, setVisible] = useState(false);

  const name = handleUserFullName(enrollment.student);

  async function onConfirm({ presence, reportId: id }: onConfirm) {
    await update({ id, changes: { presence, presenceReward: null } });

    setVisible(false);
  }

  function onCancel() {
    setVisible(false);
  }

  async function handlePresence(presence: PresenceEnum) {
    if (report) {
      const id = report.id;
      if (presence === PresenceEnum.MISSED) return setVisible(true);
      else {
        const setedPresence = presence === PresenceEnum.NONE ? null : presence;
        await update({
          id,
          changes: { presence: setedPresence },
        });
      }
    } else {
      await create({
        student: studentId,
        scheduledLesson: scheduledLessonId,
        presence,
      });
    }
    handleEnrollment(enrollment);
  }

  async function handleActivation() {
    if (!report) return;

    const id = report.id;
    await update({
      id,
      changes: { status: ScheduledLessonReportEnum.ACTIVE },
    });
  }

  const status = studentReportStatus({
    enrollmentStatus: enrollment.status,
    reportStatus: report?.status,
  });

  const activeStatus: EnrollmentAndReportStatus[] = [
    EnrollmentStatusEnum.ACTIVE,
    ActiveEnum.ACTIVE_IN_THIS_LESSON,
  ];

  const disabledClassname = activeStatus.includes(status)
    ? ''
    : 'disabled grayscale-[80] cursor';

  const loading = loadingReport || loadingCreate || loadingUpdate;

  async function onClickEnrollment(enrollment: Enrollment) {
    if (!report)
      await create({
        student: studentId,
        scheduledLesson: scheduledLessonId,
      });

    handleEnrollment(enrollment);
  }

  return (
    <li
      data-testid="studentReport"
      className={`flex flex-col xl:flex-row flex-wrap gap-2 justify-start items-start ${
        loadingCreate ? 'disabled' : ''
      }`}
    >
      <RemoveAttendanceModal
        onCancel={onCancel}
        onConfirm={onConfirm}
        visible={visible}
        name={name}
        reportId={report?.id}
      />

      <div
        className={`flex items-center gap-2 w-full flex-1 ${disabledClassname}`}
      >
        <PresenceCheckbox
          key={report?.id}
          presence={report?.presence}
          onClick={handlePresence}
          loading={loading}
          disabled={!permissions.hasPresencePermission}
        />

        <Avatar
          testId="studentAvatar"
          size="6"
          userId={enrollment.student.id}
        />

        <div className="flex w-full justify-between items-center relative">
          <ConditionalRenderer
            fallback={
              <Skeleton className="flex h-8 bg-primary-content rounded-md" />
            }
            condition={!loadingReport}
          >
            <Text
              testId="name"
              text={name}
              format="rubik-500"
              className={`${
                !permissions.hasReportsPermission
                  ? 'pointer-events-none'
                  : 'cursor-pointer'
              } flex text-primary hover:text-primary/40 text-ellipsis overflow-hidden`}
              onClick={async () => await onClickEnrollment(enrollment)}
            />
            <StudentReportStatus status={status} />
          </ConditionalRenderer>
          <ConditionalRenderer condition={!!klass}>
            {klass && (
              <NotifyLatelessButton
                key={scheduledLesson.id}
                scheduledLesson={scheduledLesson}
                scheduledLessonReport={report}
                studentId={enrollment.student.id}
                klass={klass}
                setRef={setButtonRef}
              />
            )}
          </ConditionalRenderer>
        </div>
      </div>

      <div className="border border-primary-content h-6 m-0 w-fit hidden xl:flex" />

      <ReportAssessment
        homeworkProgress={homeworkProgress}
        testId="reportAssessment"
        key={report?.id}
        report={report}
        loading={loadingReport}
      />

      <ActiveReportButton
        onActiveReport={handleActivation}
        status={status}
        locked={!permissions.hasPresencePermission}
        loading={loadingUpdate}
      />
    </li>
  );
}

type RemoveAttendanceModalProps = {
  name: string;
  reportId?: number;
  visible?: boolean;
  onConfirm({ presence, reportId }: onConfirm): Promise<void>;
  onCancel(): void;
};

function RemoveAttendanceModal({
  name,
  reportId,
  visible = false,
  onConfirm,
  onCancel,
}: RemoveAttendanceModalProps) {
  const presence = PresenceEnum.MISSED;

  if (reportId) {
    return (
      <ModalDisable
        modalType="confirm"
        selectedObjectName={name}
        onClickConfirm={async () => await onConfirm({ presence, reportId })}
        onClickCancel={onCancel}
        visible={visible}
        translationString="modalStudentAbsence"
      />
    );
  }

  return null;
}

type ReportStatusProps = {
  status: EnrollmentAndReportStatus;
};

function StudentReportStatus({ status }: ReportStatusProps) {
  const { t: tEnrollments } = useTranslation('translation', {
    keyPrefix: 'enrollmentStatus',
  });

  return (
    <ConditionalRenderer condition={status !== EnrollmentStatusEnum.ACTIVE}>
      <Text
        text={tEnrollments(status)}
        className="absolute h-fit w-fit px-1.5 py-0.5 rounded-full left-3 -bottom-2 bg-primary-content text-12 whitespace-nowrap"
      />
    </ConditionalRenderer>
  );
}

type ActiveReportButtonProps = ReportStatusProps & {
  loading?: boolean;
  onActiveReport(): Promise<void>;
  locked: boolean;
};

function ActiveReportButton({
  status,
  locked,
  loading,
  onActiveReport,
}: ActiveReportButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  return (
    <ConditionalRenderer
      condition={status === TranferredEnum.TRANSFERRED_TO_CLASS}
    >
      <ConditionalWrapper
        condition={locked}
        wrapper={<Tooltip text={t('isLockedDesc')} />}
      >
        <MainButton
          icon={<CheckCircleIcon />}
          text={t('activeStudent')}
          onClick={onActiveReport}
          disabled={locked}
          loading={loading}
        />
      </ConditionalWrapper>
    </ConditionalRenderer>
  );
}
